.hero{
    display:flex;
    justify-content: space-between;
}

.left-h{
    padding : 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h{
  flex: 1;
  position: relative;
  background-color: var(--orange);

}

.the-best-ad{
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.the-best-ad>span{
  z-index: 2;
}
.the-best-ad>div{
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}
.hero-text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
   color: white;
   text-overflow: inherit;

}

.hero-text>div:nth-of-type(3)
{
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;

}
.figures{
  display: flex;
  gap: 2rem;

}
.figures>div{
  display: flex;
  flex-direction: column;

}
.figures>div>span:nth-of-type(1){
  color: white;
  font-size:2rem;

}

.figures>div>span:nth-of-type(2){
  color:#a9b2b9;
  text-transform: uppercase;


}
.hero-button{
  display: flex;
  gap:1rem;
  font-weight: normal;

}
.hero-button>:nth-child(1)
{
  color: white;
  background-color: var(--orange);
  width: 8rem;

}
.hero-button>:nth-child(2)
{
  color: white;
  background-color: transparent;
  width: 8rem;
  border:2px solid var(--orange);

  
}
.right-h>.btn{
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;

}
.heart-rate{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position:absolute;
  right:4rem;
  top: 7rem;

}
.heart-rate>img{
  width: 2rem ;
}
.heart-rate>:nth-child(2){
color: var(--gray);
}
.heart-rate>:nth-child(3){
  color: white;
  font-size: 1.5rem;
}

.hero-image{
  position: absolute;
  right: 8rem;
  top: 10rem;
  width: 23rem;
}
.hero-image-back{
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}
.calories{
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding :1rem;
  width: fit-content;
  position: absolute;
  top: 32rem;
  right:28rem;

}
.calories>img{
  width: 3rem;
}
.calories>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.calories>div> :nth-of-type(1){
color: var(--gray);
}
.calories>div> :nth-of-type(2){
color: white;
font-size:1.5rem;
}

.hero-blur{
  width: 25rem;
  height: 40rem;
  left: 1;
  padding-left: 10rem;
}


@media screen and (max-width: 768px) { 
  .hero{
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .the-best-ad{
    margin-top: 0;
    font-size: small;
    align-items: center;
    transform: scale(0.8);
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;

  }
  .hero-text>div:nth-of-type(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-button{
    justify-content: center;

  }
  .figures>div>span:nth-of-type(1){
    font-size: large;
  }
  .figures>div>span:nth-of-type(2){
    font-size: small;
  }
  .right-h{
    position: relative;
    background: none;
  }
  .heart-rate{
    left: 1rem;
    top: 2rem;
  }
  .calories{
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories>div>:nth-child(2){
    color: white;
    font-size: 1rem;

  }
  .hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;align-self: center;

  }
  .hero-image-back{
    width: 15rem;
    left: 2rem;
    top: 0rem;
  }
}